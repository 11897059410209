<template>
<div class="contents_855">
  <header-bar active="wash-request"></header-bar>

  <div class="form_title">
    <button v-if="isOnetime" v-bind:class="activeClass01" class="title2">{{ $t('washRequest.spotOrder') }}</button>
    <button v-if="!isOnetime" @click="tabSelect1()" v-bind:class="activeClass01" class="title2">{{ $t('washRequest.spotOrder') }}</button>
    <button v-if="!isOnetime" @click="tabSelect2()" v-bind:class="activeClass02" class="title2">{{ $t('washRequest.weeklySchedule') }}</button>
  </div>

  <keep-alive>
    <component :is="currentTab" />
  </keep-alive>

  <modal
    v-if="showStatusStoppedModal"
    :title="$t('washRequest.warning')"
    :bottomCloseButtonLabel="$t('washRequest.change')"
    :showBottomDismissButton=false
    @close="editCreditInfo()"
    @dismiss="showStatusStoppedModal = false">
    <div slot="body">
      <div>
        <p class="font_orange font_medium">
          {{ $t('washRequest.paymentError', { month: dtStoppedMonth }) }}<br>
          {{ $t('washRequest.changeCreditCard') }}<br><br>
          {{ $t('washRequest.changePlanInstruction') }}<br><br>
          {{ $t('washRequest.completeMessage') }}
        </p>
      </div>
    </div>
  </modal>

</div>
</template>

<script>
import WashRequestEach from './WashRequestEach.vue'
import WashRequestWeek from './WashRequestWeek.vue'
import WashRequestOnetime from './WashRequestOnetime/WashRequestOnetime.vue'
import userApi from '@/api/user'
import { dtFormat } from '@/filter'

export default {
  name: 'wash-request',
  components: {
    WashRequestEach,
    WashRequestWeek,
    WashRequestOnetime,
  },
  data() {
    return {
      currentTab: 'WashRequestEach',
      activeClass01: 'title_active',
      activeClass02: 'title_inactive',
      showStatusStoppedModal: false,
      stoppedMonth: '',
      isOnetime: false,
    }
  },
  async mounted() {
    const token = this.$store.getters['localStorage/serverToken']
    if (!token) {
      this.$router.push({name: 'login', query: this.$route.query})
    }
    await this.getMe()
    userApi.getStoppedInfo().then(({ data }) => {
      const user = data['user'];
      if (user['status'] == 2 && user['payment_method_id'] == 1) {
        const stoppedUserInfos = data['stopped_infos']
        stoppedUserInfos.forEach(k => {
          if (k['type'] == 1 && k['solved'] == 0) {
            this.stoppedMonth = k['updated_at']
          }
        })
        if (!this.isOnetime) {
          this.showStatusStoppedModal = true
        }
      }
    })
  },
  methods: {
    async getMe() {
      await this.$store.dispatch('user/getMe')
        .then(me => {
          const planInfos = me.user_plan.plan_id.split('_')
          if (planInfos[0] == 'tx') {
            this.isOnetime = true
          }
          if (this.isOnetime) {
            this.currentTab = 'WashRequestOnetime'
          }
      })
    },
    tabSelect1() {
      this.activeClass01 = 'title_active'
      this.activeClass02 = 'title_inactive'
      this.currentTab = 'WashRequestEach'
    },
    tabSelect2() {
      this.activeClass01 = 'title_inactive'
      this.activeClass02 = 'title_active'
      this.currentTab = 'WashRequestWeek'
    },
    editCreditInfo() {
      this.showStatusStoppedModal = false
      this.$router.push({name: 'plan-edit'})
    },
  },
  computed: {
    dtStoppedMonth() {
      return dtFormat(this.stoppedMonth, 'm')
    },
  },
}
</script>

<style lang="scss" scoped>
.form_title{
    display:flex;
}
.title_active,.title_inactive{
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 0;
  text-align:center;
  font-size: 16px;
  font-weight: normal;
  clear:both;
}
.title_active{
  background-color: #00a9ca;
  color: #fff;
}
.title_inactive{
  background-color: #b7e6f1;
  color: #00a9ca;
}
.pickup_quantity{
  width:100px;
}
</style>
