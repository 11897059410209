function validatePassword(pw) {
  const allowedReg = /^[a-zA-Z0-9-_!@#\$%^&\*\(\)+=~`\{\}\[\]'":;\/?,.<>]{8,}$/
  return pw.match(allowedReg)
}

function validateArea(params, areaInfos) {
  // 郵便番号が取得できない場合はエラー
  if (!params.zipcode) {
    return false
  }
  // 郵便番号が7桁でない場合はエラー
  if (!params.zipcode.match(/^\d{7}$/)) {
    return false
  }

  const planInfos = params.plan_id.split('_')
  const isOnetime = planInfos[0] === 'tx'
  let isZipcodeFound = false
  for (const info of areaInfos) {
    if (params.zipcode.slice(0, 5) !== info.zipcode) { continue }
    isZipcodeFound = true

    if (isOnetime && !info.is_valid_onetime) {
      return false
    }
    if (!isOnetime && !info.is_valid_monthly) {
      return false
    }
  }
  if (!isZipcodeFound) {
    return false
  }

  return true
}

function getUserValidationRulesCommon(opts) {
  const ret = {
    requiredNameParams: [
      'name',
      'kana',
    ],
    generalRequiredParams: [
      'bill_addr_zipcode',
      'bill_addr_detail1',
      'bill_addr_detail2',
      'send_addr_name',
      'send_addr_zipcode',
      'send_addr_detail1',
      'send_addr_detail2',
      'phone_number',
      'email',
      'password',
      'payment_method_id',
    ],
    confirmableParams: [
      'email',
      'password',
    ],
    zipcodeParams: [
      { param: 'bill_addr_zipcode', checkArea: false },
      { param: 'send_addr_zipcode', checkArea: true },
    ],
  }
  if (opts.noPasswordCheck) {
    ret.generalRequiredParams = ret.generalRequiredParams.filter(e => !['password', 'password_confirm'].includes(e))
    ret.confirmableParams = ret.confirmableParams.filter(e => !['password'].includes(e))
  }
  return ret
}

function getUserValidationRulesIndividual(opts) {
  return getUserValidationRulesCommon(opts)
}

function getUserValidationRulesCompany(opts) {
  const ret = getUserValidationRulesCommon(opts)
  ret.requiredNameParams.push(
    'company_ceo_name',
    'company_ceo_name_kana',
  )
  ret.generalRequiredParams.push(
    'company_name',
    'company_name_kana',
    'company_hq_phone_number',
  )
  ret.confirmableParams.push(
    'email2',
  )
  return ret
}

export function validateUserParams(userState, planId, areaInfos, opts = {}) {
  const errors = {}
  const validationRules = userState.is_company ? getUserValidationRulesCompany(opts) : getUserValidationRulesIndividual(opts)

  validationRules.requiredNameParams.forEach(param => {
    if (!userState[`${param}1`] || !userState[`${param}2`]) {
      errors[param] = true
      errors[`${param}_required`] = true
    }
  })

  validationRules.generalRequiredParams.forEach(param => {
    if (!userState[param]) {
      errors[param] = true
      errors[`${param}_required`] = true
    }
  })

  if (userState.password && !validatePassword(userState.password)) {
    errors.password_invalid = true
  }

  validationRules.confirmableParams.forEach(param => {
    const val1 = userState[param]
    if (!val1) { return }
    const val2 = userState[`${param}_confirm`]
    if (val1 !== val2) {
      errors[`${param}_confirm`] = true
      errors[`${param}_confirm_unmatch`] = true
    }
  })

  validationRules.zipcodeParams.forEach(({ param, checkArea }) => {
    const val = userState[param]
    if (!val) { return }
    if (!val.match(/^\d{7}$/)) {
      errors[`${param}`] = true
      errors[`${param}_invalid`] = true
    }

    if (!checkArea) { return }
    const validateAreaResult = validateArea(
      { zipcode: val, plan_id: planId },
      areaInfos
    )
    if (!validateAreaResult) {
      errors[`${param}`] = true
      errors[`${param}_notarea`] = true
    }
  })

  const isOk = Object.keys(errors).length === 0
  return [isOk, errors]
}
