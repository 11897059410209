import axios from 'axios'
import * as utils from './api-utils'

export default {
  register(data) {
    return axios.post(utils.getApiUrl('/register'), data)
  },
  registerAtPartnerVenue(venueCode, data) {
    return axios.post(utils.getApiUrl(`/venues/${venueCode}/register`), data)
  },
  updateRegisterAtPartnerVenue(venueCode, data) {
    return axios.put(utils.getApiUrl(`/venues/${venueCode}/register`), data)
  },
  login(data) {
    return axios.post(utils.getApiUrl('/login'), data)
  },
  logout() {
    return axios.post(utils.getApiUrl('/logout'))
  },
  getMe() {
    return axios.get(utils.getApiUrl('/users/me'))
  },
  updateMe(data) {
    return axios.post(utils.getApiUrl('/users/me'), data)
  },
  updateMyPassword(data) {
    return axios.post(utils.getApiUrl('/users/me/password'), data)
  },
  forgotMyPassword(data) {
    return axios.post(utils.getApiUrl('/password/forgot'), data)
  },
  resetMyPassword(data) {
    return axios.post(utils.getApiUrl('/password/reset'), data)
  },
  deleteMe() {
    return axios.delete(utils.getApiUrl('/users/me'))
  },
  withdraw() {
    return axios.delete(utils.getApiUrl('/withdraw'))
  },
  getStoppedInfo() {
    return axios.get(utils.getApiUrl('/stoppedUserInfos'))
  },
  getMyCurrentPlanWithOption() {
    return axios.get(utils.getApiUrl('/users/me/currentUserPlanWithOption'))
  },
  getMyCurrentPlan() {
    return axios.get(utils.getApiUrl('/users/me/currentUserPlan'))
  },
  getMyCurrentPlanTemp() {
    return axios.get(utils.getApiUrl('/users/me/currentUserPlanTemp'))
  },
  updateMyPlan(data) {
    return axios.post(utils.getApiUrl('/users/me/userPlan'), data)
  },
  getMyWashRequests() {
    return axios.get(utils.getApiUrl('/users/me/washRequests'))
  },
  addWashRequest(data) {
    return axios.post(utils.getApiUrl('/users/me/washRequests'), data)
  },
  addWeekRequest(data) {
    return axios.post(utils.getApiUrl('/users/me/weekRequests'), data)
  },
  addOnetimeWashRequest(data) {
    return axios.post(utils.getApiUrl('/users/me/onetimeRequests'), data)
  },
  checkWeekRequest(data) {
    return axios.post(utils.getApiUrl('/users/me/checkWeekRequests'), data)
  },
  updateWashRequest(id, data) {
    return axios.put(utils.getApiUrl(`/users/me/washRequests/${id}`), data)
  },
  cancelWashRequest(id) {
    return axios.delete(utils.getApiUrl(`/users/me/washRequests/${id}`))
  },
  addWashRequestCount(data) {
    return axios.post(utils.getApiUrl('/users/me/washRequestCount'), data)
  },
  updateCurrentLang(data) {
    return axios.post(utils.getApiUrl('/users/me/language'), data)
  },
  getBusinessHolidays(data) {
    return axios.get(utils.getApiUrl('/businessHolidays'))
  },
  getUserWeekRequests(data) {
    return axios.get(utils.getApiUrl('/userWeekRequests'))
  },
  getToken(id) {
    return axios.get(utils.getApiUrl(`/getToken/${id}`))
  },
  getOnetimePayments() {
    return axios.get(utils.getApiUrl('/onetimePayments'))
  },
  saveAcquisition(data) {
    return axios.post(utils.getApiUrl('/acquisition'), data)
  }
}
