<template>
  <tr>
    <th><p class="item2">{{itemLabel}}<span class="font_orange">*</span></p></th>
    <td>
      <p class="font_blue mg_bt5">{{ $t('address.zipcode') }}</p>
      <p>
        {{ userState[userZipcodePropName] }}
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${userZipcodePropName}_notarea`]">
          {{ $t('address.areaUnavailable') }}
        </span>
      </p>
      <p class="font_blue pd10 clear_b">{{ $t('address.prefecture') }}</p>
      <p>
        {{ userState[userAddress1PropName] }}
      </p>
      <p class="font_blue pd10 clear_b">{{ $t('address.streetAddress') }}</p>
      <p>
        {{ userState[userAddress2PropName] }}
      </p>
      <p class="font_blue pd10 clear_b">{{ $t('address.building') }}</p>
      <p>
        {{ buildingName }}
      </p>
      <p class="font_blue pd10 clear_b">{{ $t('address.room') }}</p>
      <p>
        <input
          type="text"
          class="form_basic"
          name="address-line3"
          autocomplete="address-line3"
          v-model="roomNumber"
          :class="{error_outline: userErrorState.errors[`${userAddress3PropName}_room_number`]}"
        />
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${userAddress3PropName}_room_number_required`]">
          ▲ {{ $t('required') }}
        </span>
      </p>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'

export default defineComponent({
  props: {
    itemLabel: {
      type: String,
      required: true,
    },
    itemNamePrefix: {
      type: String,
      required: true,
      validator(value) {
        return ['bill', 'send'].includes(value)
      },
    },
  },
  setup(props) {
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    const buildingName = ref('')
    const roomNumber = ref('')
    const isZipcodeSearchError = ref(false)

    watch(() => roomNumber.value, () => {
      userState[`${props.itemNamePrefix}_addr_detail3_room_number`] = roomNumber.value
    })

    const userZipcodePropName = computed(() => {
      return `${props.itemNamePrefix}_addr_zipcode`
    })
    const userAddress1PropName = computed(() => {
      return `${props.itemNamePrefix}_addr_detail1`
    })
    const userAddress2PropName = computed(() => {
      return `${props.itemNamePrefix}_addr_detail2`
    })
    const userAddress3PropName = computed(() => {
      return `${props.itemNamePrefix}_addr_detail3`
    })

    watch(() => userState[`${props.itemNamePrefix}_addr_detail3`], () => {
      initializeRoomNumber()
    })

    onMounted(() => {
      // venueの住所を集配先住所に設定
      if (!userState[`${props.itemNamePrefix}_addr_zipcode`]) {
        userState[`${props.itemNamePrefix}_addr_zipcode`] = userState.venue.send_addr_zipcode
      }
      if (!userState[`${props.itemNamePrefix}_addr_detail1`]) {
        userState[`${props.itemNamePrefix}_addr_detail1`] = userState.venue.send_addr_detail1
      }
      if (!userState[`${props.itemNamePrefix}_addr_detail2`]) {
        userState[`${props.itemNamePrefix}_addr_detail2`] = userState.venue.send_addr_detail2
      }
      if (!userState[`${props.itemNamePrefix}_addr_detail3`]) {
        userState[`${props.itemNamePrefix}_addr_detail3`] = userState.venue.send_addr_detail3
      }
      initializeRoomNumber()
    })

    const initializeRoomNumber = () => {
      const sendAddrDetail3 = userState[`${props.itemNamePrefix}_addr_detail3`]
      if (!sendAddrDetail3) return
      const [building, room] = sendAddrDetail3.split('@')
      buildingName.value = building || ''
      roomNumber.value = room || ''
    }

    return {
      userState,
      userErrorState,
      // refs
      buildingName,
      roomNumber,
      isZipcodeSearchError,
      // computed
      userZipcodePropName,
      userAddress1PropName,
      userAddress2PropName,
      userAddress3PropName,
    }
  }
})
</script>

<style lang="scss" scoped>
.zipcode-search,
.copy-from-billing-address {
  cursor: pointer;
}
.zipcode-search-err-msg {
  line-height: 2.5;
}

.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
</style>
