<template>
  <div id="app">
    <env-logo></env-logo>
    <global-header></global-header>
    <router-view></router-view>
    <global-footer></global-footer>
  </div>
</template>

<script>
import axios from 'axios'
import GlobalHeader from '@/components/lib/GlobalHeader'
import GlobalFooter from '@/components/lib/GlobalFooter'
import EnvLogo from '@/components/lib/EnvLogo'
import routeMixin from '@/mixin/routeMixin'
import masterApi from '@/api/master'
import userApi from '@/api/user'
import { referrerCode } from "@/consts/referrer";

export default {
  name: 'app',
  created() {
    this.checkMaintenance()
    this.setAxios()
    this.setupMaster()
    this.setReferrer()
  },
  mixins: [routeMixin],
  methods: {
    setAxios() {
      // content type
      axios.defaults.headers['Content-Type'] = 'application/json'
      // token
      const token = this.$store.getters['localStorage/serverToken']
      axios.defaults.headers['Authorization'] = `Bearer ${token}`

      // go to login if 401 is returned
      axios.interceptors.response.use(response => {
        return response
      }, error => {
        const st = error.response.status
        if (st === 401 && !this.isGuestPage()) {
          this.setReferrer()
          this.$router.push({name: 'login', query: this.$route.query})
        }
        return Promise.reject(error)
      });

      if (!this.isGuestPage()) {
        this.$store.dispatch('user/getMe')
            .then(() => {
              // nothing to do
            })
            .catch(e => {
              this.setReferrer()
              this.$router.push({name: 'login', query: this.$route.query})
            })
      }
    },
    setupMaster() {
      window.master = {
        $promise: masterApi.getAll()
      }
      window.master.$promise.then(data => {
        const prms = window.master.$promise
        window.master = data
        window.master.$promise = prms
      })
    },
    checkMaintenance() {
      masterApi.maintenanceIndex().then(({ data }) => {
        const maint_flg = data.maint_flg
        if (maint_flg === 1 && !this.$route.name.match(/login/) && !this.isAdminPage() && !this.isStaffPage()) {
          this.$router.push({name: 'maintenance'})
        }
        if (maint_flg === 0 && this.$route.name.match(/maintenance/) && !this.isAdminPage() && !this.isStaffPage()) {
          this.$router.push({name: 'login'})
        }
      })
    },
    setReferrer() {
      if (this.isFirstPage()) {
        let referrerSource = '';
        if ('aw_sourse' in this.$route.query) {
          referrerSource = this.$route.query.aw_sourse
        } else if (localStorage.getItem('user_acquisition_source')) {
          referrerSource = localStorage.getItem('user_acquisition_source')
        } else {
          let referrer = document.referrer
          if (referrer.includes('google.com')) {
            referrerSource = referrerCode.organicSearchGoogle
          } else if (referrer.includes('search.yahoo.co.jp')) {
            referrerSource = referrerCode.organicSearchYahoo
          } else if (referrer.includes('bing.com')) {
            referrerSource = referrerCode.organicSearchBing
          } else {
            referrerSource = referrerCode.other
          }
        }

        localStorage.setItem('user_acquisition_source', referrerSource)
        if ('register'.indexOf(this.$route.name) !== -1) {
          userApi.saveAcquisition({
            'referrer_code': referrerSource
          })
        }
      }
    }
  },
  components: { GlobalHeader, GlobalFooter, EnvLogo }
}
</script>

<style lang="scss">
@import '../style/global_vars.scss';
</style>

<style scoped lang="scss">
#app {
  position: relative;
}
</style>
