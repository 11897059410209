<template src="./template.html"></template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import userApi from '@/api/user'
import areaInfoApi from '@/api/areaInfo'
import dateMixin from '@/mixin/dateMixin'
import { monthOptions, dateOptions, hourOptions, minuteOptions } from '@/consts/selectOptions'
import OnetimePaymentMethodInput from '@/components/WashRequestOnetime/components/OnetimePaymentMethodInput.vue'
import {
  lovKeyOnetimePaymentMethodCreditCard,
  lovKeyOnetimePaymentMethodPaid,
  lovKeyOnetimePaymentMethodPaypay
} from '@/consts/lov'

function getMinuteOptions(filterValues = [0, 15, 30, 45]) {
  return minuteOptions.filter(option => filterValues.includes(option.val));
}

export default {
  name: 'wash-request',
  components: { OnetimePaymentMethodInput },
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      masterState: {},
      currentPlan: {},
      months: monthOptions,
      dates: dateOptions,
      hours: hourOptions,
      minutes: getMinuteOptions(),
      minutes30: getMinuteOptions([0, 15, 30]),
      minutes0: getMinuteOptions([0]),
      pickupDates: [],
      deliveryDates: [],
      pickupConvHours: hourOptions,
      deliveryConvHours: hourOptions,
      lastPickupConvHours: null,
      lastDeliveryConvHours: null,
      pickupMinutes: getMinuteOptions(),
      deliveryMinutes: getMinuteOptions(),
      areaInfo: [],

      counts: [
        1,2,3,4,5
      ],
      pickupYear: 9999,
      pickupMonth: 1,
      pickupDate: 1,
      pickupHour: 13,
      pickupMinute: 0,
      pickupDt: null,
      deliveryYear: 9999,
      deliveryMonth: 1,
      deliveryDate: 1,
      deliveryHour: 16,
      deliveryMinute: 0,
      deliveryDt: null,
      count: 1,
      deliveryBoxPw: '',
      source_venue_code: '',
      roomNumber: '',
      price: '',
      unitPrice: '',
      onetimePaymentMethodId: '1',
      // Business Rule No.20 備考欄は最大で全角500文字まで入力可能
      bikou: '',
      zipcode: '',
      businessHolidays: [],
      onetimePayments: [],
      paymentMethodState: {
        paymentMethodId: '1',
        isPending: false,
        additionalInfo: {},
      },

      paymentInfo: {
        url: '',
        formParams: [],
        retUrl: '',
      },

      showErrMsg1: false,
      showErrMsg2: false,
      showErrMsg3: false,
      showErrMsg4: false,
      showErrMsg5: false,

      isWaitingResponse: false,
      isDupRequest: false,
      isStatusDisabled: false,
      isStatusWithdrawn: false,
      isStatusStoppedOther: false,

      lovKeyOnetimePaymentMethodPaypay,
    }
  },
  computed: {
    ...mapState('user', {
      // Business Rule No.17 x月はあとy個使えます
      availableRequestCount: state => state.available_request_count,
      isSaturdayAvailable: state => state.is_saturday_available,
      role: state => state.role,
      sendAddrZipcode: state => state.send_addr_zipcode,
      withdrawalDt: state => state.withdrawal_dt,
    }),
    toPickupTime() {
      let now = new Date(new Date().valueOf())
      if (this.isStep2) {
        now = new Date(this.pickupDt.valueOf())
      } else {
        now.setHours(this.pickupHour)
        now.setMinutes(this.pickupMinute)
      }
      return new Date(now.valueOf() + 60*90*1000)
    },
    addWashRequestConfirmButtonText() {
      return this.paymentMethodState.paymentMethodId === lovKeyOnetimePaymentMethodPaid
        ? this.$t('washRequestOnetime.form.confirmDeliveryRequest')
        : this.$t('washRequestOnetime.form.enterPaymentInfo')
    },
    onetimePaymentMethodName() {
      if (!this.masterState.lovsMap || !this.masterState.lovsMap.onetime_payment_method) {
        return ''
      }
      return this.masterState.lovsMap.onetime_payment_method[this.paymentMethodState.paymentMethodId].val
    },
  },
  async mounted() {
    // 休業日取得
    this.businessHolidays = await this.getBusinessHolidays()
    // 単発料金取得
    const { data: onetimePaymentsData } = await userApi.getOnetimePayments()
    this.onetimePayments = onetimePaymentsData

    // 集配日時デフォルト値設定
    this.zipcode = this.sendAddrZipcode
    await this.getMe()
    const obj = {
      'zipcode': this.zipcode,
      'is_onetime': 1
    }
    areaInfoApi.getAreaInfo(obj).then(({ data }) => {
      this.areaInfo = data

      this.pickupConvHours = this.pickupConvHours.filter(v => v.val >= 13 && v.val <= 20)
      this.deliveryConvHours = this.deliveryConvHours.filter(v => v.val >= 16 && v.val <= 22)
      if (!data['error']) {
        this.lastPickupConvHours = this.pickupConvHours[this.pickupConvHours.length -1].val
        this.lastDeliveryConvHours = this.deliveryConvHours[this.deliveryConvHours.length -1].val
      }

      let pickupDt = new Date(new Date().valueOf())
      // 分に余りがあったら時間を進める
      let remainder = pickupDt.getMinutes() % 15
      if (remainder > 0) {
        pickupDt = new Date(pickupDt.valueOf() + 60*(15 - remainder)*1000);
      }

      // プラスした時間が当日中最終選択時間よりあとの場合はデフォルトの時間を翌日にするために更に時間をプラスする
      if (pickupDt.getHours() > this.lastPickupConvHours || (pickupDt.getHours() == this.lastPickupConvHours && pickupDt.getMinutes() > this.minutes30[this.minutes30.length -1].val)) {
        let to24 = 24 - pickupDt.getHours()
        pickupDt = new Date(pickupDt.valueOf() + 3600*to24*1000)
      }

      // 選べる時間が13時以降なので、それより前だったらそこまで進める
      const pickupHour = pickupDt.getHours()
      const pickupHourDiff = 13 - pickupHour
      if (pickupHourDiff > 0) {
        pickupDt.setHours(13)
        pickupDt.setMinutes(0)
      }

      pickupDt = this.getAvailableDate(pickupDt, this.businessHolidays, this.isSaturdayAvailable)  // 休業日考慮
      this.pickupYear = pickupDt.getFullYear()
      this.pickupMonth = pickupDt.getMonth() + 1
      this.pickupDate = pickupDt.getDate()
      this.pickupHour = pickupDt.getHours()
      this.pickupMinute = pickupDt.getMinutes()
      if (this.lastPickupConvHours) {
        if ((pickupDt.getHours() > this.lastPickupConvHours)
        || (pickupDt.getHours() == this.lastPickupConvHours) && pickupDt.getMinutes() > this.minutes[this.minutes.length -2]) {
          this.pickupHour = this.lastPickupConvHours
          this.pickupMinute = 30
        }
      }

      let deliveryDt = new Date(pickupDt.valueOf() + 86400*1*1000)
      // 選べる時間が16時以降なので、それより前だったらそこまで進める
      const deliveryHour = pickupDt.getHours()
      const deliveryHourDiff = 16 - deliveryHour
      if (deliveryHourDiff > 0) {
        deliveryDt.setHours(16)
        deliveryDt.setMinutes(0)
      }

      deliveryDt = this.getAvailableDate(deliveryDt, this.businessHolidays)  // 休業日考慮
      // 期間内で休日を除き24時間以上の時間があるか確認
      const workingDay = this.getWorkingDay(pickupDt, deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
      if (workingDay < 2) {
        // 営業日数が２日未満の場合
        const addDate = 2 - workingDay
        deliveryDt.setDate((deliveryDt.getDate() + addDate))
      }
      this.deliveryYear = deliveryDt.getFullYear()
      this.deliveryMonth = deliveryDt.getMonth() + 1
      this.deliveryDate =  deliveryDt.getDate()
      this.deliveryHour =  deliveryDt.getHours()
      if (this.lastDeliveryConvHours) {
        if (deliveryDt.getHours() > this.lastDeliveryConvHours) {
          this.deliveryHour = this.lastDeliveryConvHours
          this.deliveryMinute = 0
        } else {
          this.deliveryMinute = deliveryDt.getMinutes()
        }
      }

      this.setPickupDates()
      this.setDeliveryDates()
      this.setPickupMinutes()
      this.setDeliveryMinutes()
      this.setPrice()
    })

    window.master.$promise.then(mst => {
      this.masterState = mst
      userApi.getMyCurrentPlan().then(({ data }) => {
        const planMst = mst.planMap[data.plan_id]
        Object.keys(planMst).forEach(k => {
          data[`mst_${k}`] = planMst[k]
        })
        // Business Rule No.16 ご利用プラン：契約中のプランが表示される
        this.currentPlan = data
      })
    })
  },
  mixins: [dateMixin],
  methods: {
    async getMe() {
      await this.$store.dispatch('user/getMe')
        .then(me => {
          if(!this.zipcode) {
            this.zipcode = me.send_addr_zipcode
          }
          if (me.source_venue_code) {
            this.source_venue_code = me.source_venue_code
            this.roomNumber = me.send_addr_detail3.includes('@') ? me.send_addr_detail3.trim().split('@')[1] : ''
          }
      })
    },
    changeDate(wreqType, dateType) {
      if (wreqType === 1) {
        if (dateType === 1) {
          this.setPickupDates()
        } else if (dateType === 3) {
          this.setPickupMinutes()
        }
      } else if (wreqType === 2) {
        if (dateType === 1) {
          this.setDeliveryDates()
        } else if (dateType === 3) {
          this.setDeliveryMinutes()
        }
      }
      this.setPrice()
    },
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    setPickupDates() {
      // 対象年月から休業日を除く日付を取得
      const yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      this.pickupDates = this.getWeekDaysList(this.pickupYear + yearAdjust, this.pickupMonth, this.businessHolidays, this.isSaturdayAvailable)
      if (this.pickupDates.indexOf(this.pickupDate) === -1) {
        const pickupDt = new Date(
          this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDates[0],
          this.pickupHour, this.pickupMinute, 0)
        this.pickupDt = this.getAvailableDate(pickupDt, this.businessHolidays, this.isSaturdayAvailable)
        this.pickupDate = this.pickupDt.getDate()
      }
    },
    setDeliveryDates() {
      // 対象年月から休業日を除く日付を取得
      const yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      this.deliveryDates = this.getWeekDaysList(this.deliveryYear + yearAdjust, this.deliveryMonth, this.businessHolidays, this.isSaturdayAvailable)
      if (this.deliveryDates.indexOf(this.deliveryDate) === -1) {
        const deliveryDt = new Date(
          this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDates[0],
          this.deliveryHour, this.deliveryMinute, 0)
        this.deliveryDt = this.getAvailableDate(deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
        this.deliveryDate = this.deliveryDt.getDate()
      }
    },
    setPickupMinutes() {
      if (this.lastPickupConvHours === this.pickupHour) {
        // 20時を選択した場合、30分まで選択可能とする
        this.pickupMinute = Math.min(this.pickupMinute, 30)
        this.pickupMinutes = this.minutes30
      } else {
        this.pickupMinutes = this.minutes
      }
    },
    setDeliveryMinutes() {
      if (this.lastDeliveryConvHours === this.deliveryHour) {
        // 22時を選択した場合、00分まで選択可能とする
        this.deliveryMinute = Math.min(this.deliveryMinute, 0)
        this.deliveryMinutes = this.minutes0
      } else {
        this.deliveryMinutes = this.minutes
      }
    },
    setPrice() {
      if (this.checkStep1(true)) {
        let week = this.pickupDt.getDay()
        let time = this.pickupDt.getHours() + ':00:00'
        let delivery_type = 1

        // 休業日を考慮
        let deliveryDtOnly = this.deliveryDt;
        const workingDay = this.getWorkingDay(this.pickupDt, this.deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
        if (workingDay < 3) {
          const diff = Math.round((this.deliveryDt - this.pickupDt) / (1000 * 60 * 60 * 24)) + 1;
          deliveryDtOnly = new Date(this.deliveryDt.valueOf() - 86400*diff*1000)
        }
        // 翌日 or 翌々日判定
        if (Math.floor((Math.abs(deliveryDtOnly - this.pickupDt)) / (1000 * 60 * 60 * 24)) > 1) {
          delivery_type = 2
        }
        let onetimePayment = this.onetimePayments.filter(v => v.wreq_time == time && v.delivery_type == delivery_type)
        this.price = this.count * onetimePayment[0]['week' + week]
        this.unitPrice = this.price / this.count
      } else {
        this.price = ''
        this.unitPrice =  ''
      }
    },
    checkStep1(isSetPrice = false) {
      this.resetLocalErrMsgs()
      this.resetServerErrMsgs()

      // 提供エリア外の場合は依頼不可
      if (this.areaInfo['error']) {
        if (!isSetPrice) this.showErrMsg4 = true
        return false
      }

      // 退会日が過ぎている場合は依頼不可
      const now = new Date()
      const dtFormat = Vue.filter('dtFormat')
      if (this.withdrawalDt) {
        let withdrawalDt = new Date(this.withdrawalDt)
        if (now.getTime() > withdrawalDt.getTime()) {
          this.isStatusWithdrawn = true
          return false
        }
      }

      let yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      const pickupDt = new Date(
        this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDate,
        this.pickupHour, this.pickupMinute, 0)
      yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      const deliveryDt = new Date(
        this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDate,
        this.deliveryHour, this.deliveryMinute, 0)

      if (isNaN(pickupDt.valueOf()) || isNaN(deliveryDt.valueOf())) {
        if (!isSetPrice) this.showErrMsg1 = true
        return false
      }

      // 配達時間は最短でも集荷時間の24時間後以降しか選べない
      const minDiff = 86400*1*1000
      const result = deliveryDt.valueOf() - pickupDt.valueOf() >= minDiff
      if (!result) {
        if (!isSetPrice) this.showErrMsg2 = true
        return false
      }

      // かつ配達時間は休業日を除く24時間後しか依頼できない
      const workingDay = this.getWorkingDay(pickupDt, deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
      if (workingDay < 2) {
        if (!isSetPrice) this.showErrMsg2 = true
        return false
      }

      // Business Rule No.4.1 配達時間は最長で30日後まで選択可能
      let deliveryMaxDt = new Date(now.valueOf() + 3600*24*30*1000)
      if (deliveryDt > deliveryMaxDt) {
        if (!isSetPrice) this.showErrMsg3 = true
        return false
      }

      // 選択された集荷日時が提供不可の場合は依頼不可
      if (this.price == 0) {
        if (!isSetPrice) {
          this.showErrMsg5 = true
          return false
        }
      }

      this.pickupDt = pickupDt
      this.deliveryDt = deliveryDt
      return true
    },
    checkStep2() {
      return true
    },
    async addWashRequest() {
      if (!this.checkStep1()) { return }
      if (this.isWaitingResponse) { return }

      this.resetServerErrMsgs()

      const dtFormat = Vue.filter('dtFormat')
      const reqData = {
        sched_pickup_dt: dtFormat(this.toPickupTime, 'yyyy-mm-dd HH:MM:SS'),
        sched_delivery_dt: dtFormat(this.deliveryDt, 'yyyy-mm-dd HH:MM:SS'),
        count: this.count,
        delivery_box_pw: this.deliveryBoxPw,
        room_number: this.roomNumber,
        bikou: this.bikou,
        price: this.price,
        onetime_payment_method_id: this.paymentMethodState.paymentMethodId,
      }
      // Paypay用の追加情報
      if (this.paymentMethodState.paymentMethodId === lovKeyOnetimePaymentMethodPaypay) {
        reqData.redirect_url = `${window.location.origin}/onetime-wash-request-complete`
      }
      // Paid用の追加情報
      if (this.paymentMethodState.paymentMethodId === lovKeyOnetimePaymentMethodPaid) {
        reqData.frd_key = this.paymentMethodState.additionalInfo.frdKey
      }

      this.isWaitingResponse = true
      try {
        // FIXME? エンドポイントもpaymentMethodごとに別のほうが良いかもしれない??
        const { data } = await userApi.addOnetimeWashRequest(reqData)
        if (this.paymentMethodState.paymentMethodId === lovKeyOnetimePaymentMethodCreditCard) {
          this.afterAddWashRequestForPaymentMethodCredit(data)
        } else if (this.paymentMethodState.paymentMethodId === lovKeyOnetimePaymentMethodPaypay) {
          this.afterAddWashRequestForPaymentMethodPaypay(data)
        } else if (this.paymentMethodState.paymentMethodId === lovKeyOnetimePaymentMethodPaid) {
          this.afterAddWashRequestForPaymentMethodPaid()
        }
      } catch (err) {
        if (err.response.data.reason === 'dup') {
          this.isDupRequest = true
        }
        if (err.response.data.reason === 'status_disabled') {
          this.isStatusDisabled = true
        }
        if (err.response.data.reason === 'status_withdrawn') {
          this.isStatusWithdrawn = true
          await this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
        }
        if (err.response.data.reason === 'status_stopped_other') {
          this.isStatusStoppedOther = true
        }
      } finally {
        this.isWaitingResponse = false
      }
    },
    afterAddWashRequestForPaymentMethodCredit(responseData) {
      this.updatePaymentFormValues(responseData.payment)
      setTimeout(() => {
        const form = document.querySelector('#payment-form')
        form.submit()
      }, 0)
    },
    updatePaymentFormValues(obj) {
      this.paymentInfo.url = obj.url
      this.paymentInfo.formParams = obj.form_params
      this.paymentInfo.retUrl = `${location.protocol}//${location.host}/${obj.ret_url_path}`
    },
    afterAddWashRequestForPaymentMethodPaypay(responseData) {
      window.location.href = responseData.url;
    },
    afterAddWashRequestForPaymentMethodPaid() {
      this.$router.push({ name: 'onetime-wash-request-complete' })
    },
    resetLocalErrMsgs() {
      this.showErrMsg1 = false
      this.showErrMsg2 = false
      this.showErrMsg3 = false
      this.showErrMsg4 = false
      this.showErrMsg5 = false
    },
    resetServerErrMsgs() {
      this.isDupRequest = false
      this.isStatusDisabled = false
      this.isStatusWithdrawn = false
      this.isStatusStoppedOther = false
    },
    onPaymentMethodStateChange(paymentMethodState) {
      this.paymentMethodState = paymentMethodState
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped>
</style>
